<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <crud-cpf-dialog
      :dialog="dialog"
      :titulo="'Gestor'"
      :profile="'gestor'"
      :store="'gestores'"
      :validar="'CPF'"
      @close-dialog="setFields"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <crud-card title="Dados Principais">
          <v-row>
            <v-col
              cols="9"
            >
              <v-autocomplete
                v-model="unidades"
                :loading="!unidadesNegocio"
                :items="unidadesNegocio"
                small-chips
                dense
                color="blue-grey lighten-2"
                label="Unidades de Negócio"
                item-text="filial"
                item-value="id"
                multiple
                readonly
                append-icon=""
                autocomplete="off"
                hint="Para associar ou desassociar uma Unidade Negócio, vá para o Cadastro de Unidades de Negócio."
                persistent-hint
                outlined
                rounded
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.item.attrs"
                    :input-value="data.selected"
                    small
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.filial }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title> {{ data.item.filial }} </v-list-item-title>
                    <v-list-item-subtitle> {{ ( data.item.cnpj + '  -  ' + data.item.razao_social ) }} </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="3"
            >
              <v-autocomplete
                v-model="role"
                :loading="!roles"
                :items="roles"
                small-chips
                dense
                color="blue-grey lighten-2"
                label="Papel"
                item-text="description"
                item-value="id"
                :rules="[rules.required]"
                autocomplete="off"
                outlined
                rounded
              />
            </v-col>
          </v-row>
          <crud-pessoa-fisica-base
            ref="pf"
            :optional="['rg']"
            :disabled="disabledFields"
            :input-outlined="true"
          />
          <crud-contato
            :telefones="telefones"
            :input-outlined="true"
          />
        </crud-card>
        <v-row
          class="pa-3"
          justify="center"
          justify-sm="end"
        >
          <v-btn
            class="text-none mr-3 text-white"
            color="error"
            rounded
            @click="$router.push({ path: `/${$user.get().role}/gestores` })"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            class="text-none text-white"
            color="success"
            :disabled="desabilitaBotao"
            rounded
            @click="submitForm"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Salvar
          </v-btn>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import GestorFormStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/gestor'
  import { mapState, mapActions } from 'vuex'

  export default {
    data () {
      return {
        rules,
        valid: false,
        telefones: [
          {
            tipo: 'Comercial',
            format: 'com',
            title: 'Telefone 1',
            required: true,
          },
          { tipo: 'Comercial', format: 'com', title: 'Telefone 2' },
        ],
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/gestor', ['unidadesNegocio', 'roles', 'loading', 'isEditing']),
      ...mapFields('form', ['data.unidades', 'data.role']),
      ...mapFields('form/gestor', ['dialog']),
      disabledFields () {
        if (this.loaded || this.isEditing) {
          return ['email', 'cpf']
        }
        return ['cpf']
      },
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.gestor) { this.$store.registerModule(['form', 'gestor'], GestorFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/gestor', [BOOTSTRAP_PROFILE, SUBMIT]),
      async setFields (data) {
        this.dialog = false

        if (data.value) {
          const { tipo, value } = data
          this.picked = tipo === 'cpf' ? 'fisica' : 'juridica'
          this.$store.commit('form/UPDATE_FORM_FIELD', { [tipo]: value })
          return
        }

        this.loadedData = true

        const id = data.id
        this.$router.push({
          path: `/${this.$user.get().role}/gestores/cadastro/`,
          query: { id },
        })
        this.BOOTSTRAP_PROFILE({ id })
      },
      submitForm () {
        this.$refs.pf.$v.$touch()
        if (!this.$refs.form.validate() || this.$refs.pf.$v.$invalid) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>
