export class Gestor {
  constructor ({
    id,
    cpf,
    rg,
    unidades,
    nome,
    email,
    data_nascimento,
    sexo,
    telefones,
    role,
  } = {
    id: '',
    cpf: '',
    rg: '',
    unidades: [],
    role: '',
    nome: '',
    email: '',
    data_nascimento: '',
    sexo: '',
    telefones: [],
  }) {
    this.id = id
    this.cpf = cpf
    this.rg = rg
    this.unidades = unidades
    this.nome = nome
    this.email = email
    this.data_nascimento = data_nascimento
    this.sexo = sexo
    this.telefones = telefones
    this.unidades = unidades
    this.role = role
  }
}

export function createGestor (data) {
  return Object.freeze(new Gestor(data))
}
